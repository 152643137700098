import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import myImg from '../../Assets/logo-darkmode (1).png'
import Tilt from 'react-parallax-tilt'
import { AiFillGithub, AiFillInstagram } from 'react-icons/ai'
import { FaLinkedinIn } from 'react-icons/fa'

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: '2.6em' }}>Introduksjon </h1>
            <p className="home-about-body">
              Studerer informatikk på Universitetet i Oslo
              <br />
              <br />
              Programmert mye i
              <i>
                <b className="bold"> Java, Kotlin, Python, C, </b>mm.
              </i>
              <br />
              <br />
              Liker algoritmer, operativsystemer og nettverk :) &nbsp;
              <i>
                <b className="bold"></b> <b className="bold"></b>
              </i>
              <br />
              <br />
              <i></i>
              &nbsp;
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>HER KAN DU FINNE MEG!</h1>
            <p>
              Kontakt meg <span className="purple">på </span>
              <a href="mailto:henning.osmo@gmail.com">henning.osmo@gmail.com</a>
            </p>
            <p>eller på noen av mediene under</p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/henningnord"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/henningnordhagen/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/henningnordhage.n"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
export default Home2
