import React from 'react'
import Card from 'react-bootstrap/Card'
import { ImPointRight } from 'react-icons/im'

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: 'justify' }}>
            <span className="purple" style={{ fontWeight: 'bold' }}>
              Henning Osmo Nordhagen{' '}
            </span>
            fra <span className="purple"> Bærum, Oslo.</span>
            <br />
            Informatikkstudent på IFI, ved Universitetet i Oslo. Snart på
            sisteåret på bachelor, med forydpning i programmering og
            systemarkitektur. Jobber deltid som gruppelærer i "Introduksjon til
            datateknologi" (IN1020), og som ambassadør for UiO. Har en rekke
            verv, som bedriftskontakt i Navet og arrangementsansvarlig i MAPS (
            <span style={{ fontStyle: 'italic' }}>
              Matematikk, algoritmer og programmering for studenter
            </span>
            ).
            <br />
            <br />
            Når jeg ikke studerer, har jeg andre ting jeg liker å gjøre!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Være ute!
            </li>
            <li className="about-activity">
              <ImPointRight /> Stå på ski!
            </li>
            <li className="about-activity">
              <ImPointRight /> Hobbyprosjekter!
            </li>
          </ul>
          {/*
          <p style={{ color: "rgb(71 155 228)" }}>
            "Sånn man gjør noe er sånn man gjør alt"{" "}
          </p>
          <footer className="purple"> - Henning</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  )
}

export default AboutCard
