import React from 'react'
import Typewriter from 'typewriter-effect'

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          'Informatikkstudent på UiO',
          'Ambassadør for UiO',
          'Gruppelærer i IN1020',
          'Bedriftskontakt i Navet',
          'Arrangementsansvarlig i MAPS',
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  )
}

export default Type
