import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Github from './Github'
import Techstack from './Techstack'
import Aboutcard from './AboutCard'
import blur from '../../Assets/blur_sjokomelk.png'
import laptopImg from '../../Assets/sjokomelk.jpeg'
import Toolstack from './Toolstack'
import '../../style.css' // Importer CSS-filen

function About() {
  return (
    <section>
      <Container
        fluid
        className="about-section"
        id="about"
        style={{
          backgroundImage: `linear-gradient(to bottom left, rgba(17, 16, 16, 0.4), rgba(12, 10, 22, 0.6)), url(${laptopImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Container className="about-content">
          <Row style={{ justifyContent: 'center', padding: '10px' }}>
            <Col
              md={7}
              style={{
                justifyContent: 'center',
                paddingTop: '30px',
                paddingBottom: '50px',
              }}
            >
              <h1
                style={{
                  fontSize: '2.1em',
                  paddingBottom: '20px',
                  color: 'whitesmoke',
                }}
              >
                Hvem jeg <strong className="bold">er?</strong>
              </h1>
              <Aboutcard />
            </Col>
            <Col
              md={5}
              style={{ paddingTop: '120px', paddingBottom: '50px' }}
              className="about-img"
            >
              {/* Empty column for layout */}
            </Col>
          </Row>

          <h1 className="project-heading" style={{ color: 'whitesmoke' }}>
            Verktøy jeg <strong className="purple">bruker!</strong>
          </h1>
          <Techstack />
        </Container>
      </Container>
    </section>
  )
}

export default About
